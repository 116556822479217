// @material-ui/icons
import PeopleIcon from "@material-ui/icons/People";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DescriptionIcon from '@material-ui/icons/Description';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublicIcon from '@mui/icons-material/Public';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TravelBuddyReport from "views/TravelBuddyReport/TravelBuddyReport";
// import AggregateBiller from 'views/AggregatedBillers/AggregatedBillers'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SchoolIcon from '@mui/icons-material/School';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CustomersPage from "views/Customers/Customers.js";
import UserGroupsPage from "views/UserGroups/UserGroups.js";
import SystemGroupPage from "views/SystemUsers/SystemUsers.js";
import TransactionsPage from "views/Transactions/Transactions.js";
import AppSettingsPage from "views/AppSettings/AppSettings.js"
import DepartmentsPage from "views/Departments/Departments.js"
import CorporateOnboardingPage from "views/CorporateOnboarding/CorporateOnboarding.js";
import AddEmployeePage from "views/AddEmployee/AddEmployee.js"
import AddEmployeeFinal from "views/AddEmployeeFinal/AddEmployee.js"
import ServicesPage from "views/Services/Services.js"
import CorporateFeeEdit from "views/CorporateFeeEdit/CorporateFeeEdit.js"
import CorporateEdit from "views/CorporateEdit/CorporateEdit.js"
import CorporateLOREdit from "views/CorporateLOREdit/CorporateLOREdit.js"
import Remittances from "views/Transactions/Remittances.js"
import AvenuesBillPayments from "views/Transactions/AvenuesBillPayments.js"
import SadadBillPayments from "views/Transactions/SadadBillPayments.js"
import Dashboard from "views/Dashboard/Dashboard.js"
import CustomerEdit from "views/CustomerEdit/CustomerEdit.js";
import PendingCustomers from "views/PendingCustomers/PendingCustomers.js"
import PendingCustomersV2 from "views/PendingCustomers/PendingCustomerV2"
import onboardingQueue from "views/PendingCustomers/onboardingQueue"
import ComplianceCustomers from "views/ComplianceCustomers/ComplianceCustomers"
import Adjustments from "views/Adjustments/Adjustments.js"
import CustomerFreeze from "views/CustomerFreeze/CustomerFreeze.js"
import SadadBillers from "views/SadadBillers/SadadCategory";
import CustomerBlock from "views/CustomerBlock/CustomerBlock";
import AggregateBiller from "views/BfcBiller/Biller.js"
import CorporateDocuments from "views/CorporateDoucments/CorporateDocuments";
import CreditCardSharpIcon from '@mui/icons-material/CreditCardSharp';
import Rewards from '../views/Rewardphase2/Rewards'
import CorporateCardFee from "views/CorporateCardFee/CorporateCardFee";
import CardsActivationsReport from "views/MobileCards_Activation/CardsActivationsReport";
import ReplacedCards from "views/ReplacedCards/ReplacedCards"
import TabibCardActivationReport from "views/TabiibCardReport/TabibCardActivationReport";
import TabiibReports from "views/TabiibReports/TabiibReports";
import CardsRequestReport from "views/CardRequestReport/CardRequestReport";
import CorporateOnboardingThird from "views/CorporateFinalOnboarding/CorporateOnboarding"
import MultiCurrencyReport from "views/MultiCurrency_Report/MultiCurrency_Report";
import BillPayments from "views/Transactions/BillPayments"
import StyleIcon from '@mui/icons-material/Style';
import corporateFeeEditFinal from "views/CorporateFeeEditFinal/CorporateFeeEdit"
import CardReplaceandRetry from "views/CardReplaceandRetry-MC/Card_Replace_Retry"
import MCurrencyTransactions from "views/MultiCAddMoneyReport/MCurrency_AddMoney.js"
import SpecialRates from "views/SpecialRates/SpecialRates"
import McRequestEmail from "views/MCRequestEmail/McRequestEmail"
import RedeemIcon from '@mui/icons-material/Redeem';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Statements from "views/Statements/StatementDownlod.js"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RewardReport from "views/Rewards/Rewards";
import RewardReport2 from "views/Rewards/Rewards";
import Schools from "views/Schools/Schools"
import StarsIcon from '@mui/icons-material/Stars';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import MDCHealthReport from "views/MDCHealthReport/MDCHealthReport";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import Lmra from "views/Lmra/Lmra";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PTP_Report from "views/PTP_Report/PTP_Report"
import BillersEdit from "views/BfcBillerEdit/Biller"

import GPWUserOnboarding from "views/GPWUserOnboarding/GPWUserOnboarding"
import GPWArchivedUsers from "views/GPWArchivedUsers/GPWArchivedUsers"
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaymentsIcon from '@mui/icons-material/Payments';
import { ImageOutlined } from "@material-ui/icons";
import BfcBiller from "views/AggregatedBillers/Components/Bfcbiller";
import RedemptionReport from "views/RedemptionReport/RedemptionReport"
import RefralReport from "views/RefralReport/RefralReport"
import PTP_TB_Report from "views/PTP_TB_Report/PTP_TB_Report";
import deviceBinding from "views/deviceBinding/deviceBinding"
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import WatchList from "views/WatchList/WatchList.js"
import ViewListIcon from '@mui/icons-material/ViewList';
import GIGInsurance from 'views/GIG Insurance/GIGInsurance'
import CreditScoreTwoToneIcon from '@mui/icons-material/CreditScoreTwoTone';
import CorporatePortal from "views/corporate-portal/Corporate-portal";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/dashboard",
    sort: 1
  },
  {
    path: "/customers",
    name: "Customers",
    icon: PeopleIcon,
    component: CustomersPage,
    layout: "/dashboard",
    sort: 2
  },
  {
    path: "/customer-edit",
    name: "Customer Edit",
    icon: SupervisedUserCircleRoundedIcon,
    component: CustomerEdit,
    layout: "/dashboard",
    sort: 2.1
  },
  {
    path: "/customer-freeze",
    name: "Freeze Customer",
    icon: PersonOffIcon,
    component: CustomerFreeze,
    layout: "/dashboard",
    sort: 2.2
  },
  {
    path: "/customer-block",
    name: "Block Customer",
    icon: AppBlockingIcon,
    component: CustomerBlock,
    layout: "/dashboard",
    sort: 2.3
  },
  {
    path: "/user-groups",
    name: "User Groups",
    icon: GroupWorkIcon,
    component: UserGroupsPage,
    layout: "/dashboard",
    sort: 15
  },
  {
    path: "/departments",
    name: "Departments",
    icon: BusinessCenterIcon,
    component: DepartmentsPage,
    layout: "/dashboard",
    sort: 14
  },
  {
    path: "/system-users",
    name: "System Users",
    icon: AccountCircleIcon,
    component: SystemGroupPage,
    layout: "/dashboard",
    sort: 13
  },
  {
    path: "/app-settings",
    name: "App Settings",
    icon: SettingsIcon,
    component: AppSettingsPage,
    layout: "/dashboard",
    sort: 11
  },
  {
    path: "/corporate-onboarding",
    name: "Corporate Onboarding",
    icon: BusinessIcon,
    component: CorporateOnboardingThird,
    layout: "/dashboard",
    sort: 3
  },
  {
    path: "/corporate-fee-profile",
    name: "Corporate Fee Profiles",
    icon: AccountBalanceWalletIcon,
    component: CorporateOnboardingPage,
    layout: "/dashboard",
    sort: 8
  },
  {
    path: "/corporate-fee-edit",
    name: "Corporate Fee Edit",
    icon: AccountBalanceWalletIcon,
    component: corporateFeeEditFinal,
    layout: "/dashboard",
    sort: 6
  },
  {
    path: "/corporate-edit",
    name: "Corporate Edit",
    icon: EditIcon,
    component: CorporateEdit,
    layout: "/dashboard",
    sort: 5
  },
  {
    path: "/special-rates",
    name: "Special Rates",
    icon: RedeemIcon,
    component: SpecialRates,
    layout: "/dashboard",
    sort: 12.1
  },
  {
    path: "/corporate-lor-edit",
    name: "Corporate LOR Edit",
    icon: ContactMailIcon,
    component: CorporateLOREdit,
    layout: "/dashboard",
    sort: 7
  },
  {
    path: "/corporate-documents",
    name: "Corporate Documents",
    icon: DocumentScannerIcon,
    component: CorporateDocuments,
    layout: "/dashboard",
    sort: 7.1
  },
  {
    path: "/statements",
    name: "Statements",
    icon: CloudDownloadIcon,
    component: Statements,
    layout: "/dashboard",
    sort: 7.2
  },
  {
    path: "/employee",
    name: "Employee",
    icon: AssignmentIndIcon,
    component: AddEmployeeFinal,
    layout: "/dashboard",
    sort: 4
  },
  {
    path: "/services",
    name: "Services",
    icon: AssignmentIcon,
    component: ServicesPage,
    layout: "/dashboard",
    sort: 12
  },
  // {
  //   path: "/shufti-customers",
  //   name: "Shufti Screening",
  //   icon: PendingActionsIcon,
  //   component: PendingCustomersV2,
  //   layout: "/dashboard",
  //   sort: 13
  // },
  {
    path: "/shufti-customers",
    name: "Onboarding Queue",
    icon: PendingActionsIcon,
    component: onboardingQueue,
    layout: "/dashboard",
    sort: 13
  },
  {
    path: "/eKYC-compliance-customers",
    name: "eKYC Compliance Queue",
    icon: AssignmentIcon,
    component: ComplianceCustomers,
    layout: "/dashboard",
    sort: 13.1
  },
  {
    path: "/add-money-transactions",
    name: "Add Money Transactions",
    icon: AddCircleIcon,
    component: TransactionsPage,
    layout: "/dashboard",
    sort: 13.9
  },
  {
    path: "/remittances",
    name: "Remittances",
    icon: DriveFileMoveIcon,
    component: Remittances,
    layout: "/dashboard",
    sort: 14
  },
  {
    path: "/lmra-report",
    name: "Lmra Report",
    icon: AccountBalanceIcon,
    component: Lmra,
    layout: "/dashboard",
    sort: 14.1
  },
  {
    path: "/watch-List",
    name: "Watch List",
    icon: ViewListIcon,
    component: WatchList,
    layout: "/dashboard",
    sort: 14.2
  },

  {
    path: "/ptp-tb-report",
    name: "PTP TB Report",
    icon: AssignmentRoundedIcon,
    component: PTP_TB_Report,
    layout: "/dashboard",
    sort: 15.1
  },
  {
    path: "/bill-payments",
    name: "Bill Payments",
    icon: CardTravelIcon,
    component: BillPayments,
    layout: "/dashboard",
    sort: 15
  },
  {
    path: "/sadad-bill-payments",
    name: "Sadad Bill Payments",
    icon: PublicIcon,
    component: SadadBillPayments,
    layout: "/dashboard",
    sort: 15.2
  },
  {
    path: "/avenues-bill-payments",
    name: "Avenues Bill Payments",
    icon: CardTravelIcon,
    component: AvenuesBillPayments,
    layout: "/dashboard",
    sort: 15.3
  },
  {
    path: "/P2P-Report",
    name: "PTP Report",
    icon: AssignmentRoundedIcon,
    component: PTP_Report,
    layout: "/dashboard",
    sort: 15.4
  },

  {
    path: "/card-request-report",
    name: "Card Request Report",
    icon: SummarizeIcon,
    component: CardsRequestReport,
    layout: "/dashboard",
    sort: 16.1
  },
  {
    path: "/card-activation",
    name: "Card Activation Report",
    icon: CardTravelIcon,
    component: CardsActivationsReport,
    layout: "/dashboard",
    sort: 16.2
  },
  {
    path: "/replaced-cards",
    name: "Replaced Card Details",
    icon: SummarizeIcon,
    component: ReplacedCards,
    layout: "/dashboard",
    sort: 16.3
  },
  {
    path: "/multicurrency-cards",
    name: "Multicurrency Card Report",
    icon: StyleIcon,
    component: MultiCurrencyReport,
    layout: "/dashboard",
    sort: 16.4
  },
  {
    path: "/mc-addmoney-transactions",
    name: "MC AddMoney transactions",
    icon: AddCircleIcon,
    component: MCurrencyTransactions,
    layout: "/dashboard",
    sort: 16.5
  },
  {
    path: "/mcreplaced-card-report",
    name: "MC Replaced Card report",
    icon: SummarizeIcon,
    component: CardReplaceandRetry,
    layout: "/dashboard",
    sort: 16.6
  },
  {
    path: "/mctransaction-request-report",
    name: "MC Transaction Request Report",
    icon: FilePresentIcon,
    component: McRequestEmail,
    layout: "/dashboard",
    sort: 16.7
  },
  {
    path: "/reward_report",
    name: "Reward Report",
    icon: StarsIcon,
    component: RewardReport,
    layout: "/dashboard",
    sort: 6.1
  },
  {
    path: "/GPW-User-Onboarding",
    name: "GPW User Onboarding",
    icon: HowToRegIcon,
    component: GPWUserOnboarding,
    layout: "/dashboard",
    sort: 6.2
  },
  {
    path: "/GPW-Archived-Users",
    name: "GPW Archived Users",
    icon: ArchiveIcon,
    component: GPWArchivedUsers,
    layout: "/dashboard",
    sort: 6.3
  },
  {
    path: "/Insurance",
    name: "Insurance",
    icon: CreditScoreTwoToneIcon,
    component: GIGInsurance,
    layout: "/dashboard",
    sort: 6.4
  },
  {
    path: "/Referral Report",
    name: "Referral Report",
    icon: PaymentsIcon,
    component: RefralReport,
    layout: "/dashboard",
    sort: 6.5
  },
  {
    path: "/Redemption Report",
    name: "Redemption Report",
    icon: AssessmentIcon,
    component: RedemptionReport,
    layout: "/dashboard",
    sort: 6.6
  },
  {
    path: "/mdc-health-card",
    name: "MDC Health Card",
    icon: HealthAndSafetyIcon,
    component: MDCHealthReport,
    layout: "/dashboard",
    sort: 16.9
  },
  {
    path: "/Travel-Buddy-Report",
    name: "Travel Buddy Report",
    icon: SummarizeIcon,
    component: TravelBuddyReport,
    layout: "/dashboard",
    sort: 17.3
  },

  {
    path: "/tabiib-cards-Activation",
    name: "Tabiib Card Issuance Report",
    icon: DescriptionIcon,
    component: TabibCardActivationReport,
    layout: "/dashboard",
    sort: 17.1
  },
  {
    path: "/tabiib-reports",
    name: "Tabiib Payment Reports",
    icon: PlagiarismIcon,
    component: TabiibReports,
    layout: "/dashboard",
    sort: 17.2
  },
  {
    path: "/adjustments",
    name: "Adjustments",
    icon: AutorenewIcon,
    component: Adjustments,
    layout: "/dashboard",
    sort: 17
  },
  {
    path: "/sadad-billers",
    name: "Sadad Billers",
    icon: StorefrontIcon,
    component: SadadBillers,
    layout: "/dashboard",
    sort: 18
  },
  {
    path: "/billers-edit",
    name: "BFC PAY Billers",
    icon: AssignmentIcon,
    component: BillersEdit,
    layout: "/dashboard",
    sort: 18.2
  },
  {
    path: "/schools",
    name: "Schools",
    icon: SchoolIcon,
    component: Schools,
    layout: "/dashboard",
    sort: 19
  },
  {
    path: "/cards-fee",
    name: "Cards Fee",
    icon: AssignmentIcon,
    component: CorporateCardFee,
    layout: "/dashboard",
    sort: 6.1
  },
  {
    path: "/reward-phaseII",
    name: "Reward Inventory",
    icon: StarsIcon,
    component: Rewards,
    layout: "/dashboard",
    sort: 6.2
  },

  {
    path: "/aggregate-biller",
    name: "BFC Billers",
    icon: SummarizeIcon,
    component: AggregateBiller,
    layout: "/dashboard",
    sort: 6.3
  },
  {
    path: "/deviceBinding-report",
    name: "Device Binding Report",
    icon: InstallMobileIcon,
    component: deviceBinding,
    layout: "/dashboard",
    sort: 6.3
  },


  {
    path: "/mcCorporateLoad",
    name: "MC Corporate Load",
    icon: PaymentsIcon,
    component: CorporatePortal,
    layout: "/dashboard",
    sort: 6
  },

  // {
  //   path: "/test",
  //   name: "TestRoute",
  //   icon: PaymentsIcon,
  //   component: CorporatePortal,
  //   layout: "/dashboard",
  //   sort: .1
  // },

];

export default dashboardRoutes;

