import React, { useEffect } from "react";
import axios from "axios";
import config from "config.js"
import { useSelector } from "react-redux";
import moment from "moment";
import download from "js-file-download";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ImagesStyles from "assets/jss/material-dashboard-react/imagesStyles";
import avatar from "assets/img/no_dp.png";
import Divider from '@mui/material/Divider';
import Chip from "@material-ui/core/Chip";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slide from "@material-ui/core/Slide";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import MoonLoader from "react-spinners/MoonLoader";
import DownloadIcon from '@mui/icons-material/Download';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JSONPretty from 'react-json-pretty';
import Swal from "sweetalert2";
import Tooltip from "@material-ui/core/Tooltip";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from "components/Table/Table.js";
import TableRowsIcon from '@mui/icons-material/TableRows';
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TimelineIcon from '@mui/icons-material/Timeline';
import FeeReversal from "views/MultiCurrency_Report/Componenets/FeeReversal";
import FeeReversalLog from "views/MultiCurrency_Report/Componenets/FeeReversalLog";
import MultiCurrency_Report from "views/MultiCurrency_Report/MultiCurrency_Report";



import libs from "controllers/libs";
import { Box, FormControlLabel, Paper, Radio, TextField } from "@material-ui/core";
import { id } from "date-fns/locale";
import { setStatus } from "redux/actions/user";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;
const overrideNew = css`
display: block;
margin: 0px auto;
border-color: red;
`;
const styles = {
    ...ImagesStyles,
    ...SweetAlertStyle,
    ...modalStyle,
    statusText: {
        color: "#000 !important",
    },
    noMargin: {
        margin: "0px",
        //  marginBottom: "-20px",
        float: "left"
    },
    mdMargin: {
        margin: "10px",
        fontSize: "16px"
    },
    smMargin: {
        margin: "2px",
        fontSize: "20px",
        fontWeight: "400"
    },
    mdBold: {
        fontWeight: "400"
    },
    customLink: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    logPaper: {
        padding: "15px"
    },
    tabContent: {
        maxHeight: "60vh"
    }

};

const logColumns = [
    { id: 'date', label: 'Debit Date', width: 90 },
    { id: 'amount', label: 'Amount', width: 90 },
    { id: 'txRef', label: 'M2P Refernce ID', width: 90 },
    { id: 'reversalTransactionID', label: 'Reversal Transaction ID', width: 90 },
    { id: 'feeType', label: 'Fee Type', width: 90 },

]
const bfcPointsColumns = [
    { id: 'date', label: 'Date', width: 90 },
    { id: 'userID', label: 'User ID', width: 90 },
    { id: 'selectedOption', label: 'Debit/Credit', width: 90 },
    { id: 'bfcPoints', label: 'BFC Points', width: 90 },
    { id: 'reason', label: 'Reason', width: 90 },
    { id: 'status', label: 'Status', width: 90 },
    { id: 'remarks', label: 'Remarks', width: 90 },
    { id: 'options', label: 'Options', width: 180 },

]

const feeLogColumns = [
    { id: 'date', label: 'Date', width: 90 },
    { id: 'time', label: 'Time', width: 90 },
    { id: 'user', label: 'User', width: 90 },
    { id: 'status', label: 'Status', width: 90 },
    { id: 'status_message', label: 'Status_Message', width: 90 },
    { id: 'remarks', label: 'Remarks', minWidth: 90 },
];

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomerDetails(props) {
    const users = useSelector(state => state.user);
    const classes = useStyles();
    const [user, setUserDetails] = React.useState([]);
    const [userID, setUserID] = React.useState([]);
    const [showDetails, setShowDetails] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [documentViewerData, setDocumentViewerData] = React.useState(avatar);
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageNumberTwo, setPageNumberTwo] = React.useState(1);
    const [thumbnail, setThumbnail] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [viewLog, setViewLog] = React.useState(false)
    const [logBlock, setLogBlock] = React.useState([]);
    const [openSnack, setOpenSnack] = React.useState(false)
    const [snackBarMsg, setSnackBarMsg] = React.useState("")
    const [snackBarType, setSnackBarType] = React.useState("error")
    const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
    const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);
    const [feeReversalLog, setFeeReversalLog] = React.useState([])
    const [feeReversalLogModal, setFeeReversalLogModal] = React.useState(false)
    const [amount, setAmount] = React.useState("");
    const [showLog, setShowFeeLog] = React.useState(false)
    const [showFeeReversal, setShowFeeReversal] = React.useState(false)
    const [feeReversals, setFeeReversals] = React.useState([])
    const [txnRef, setTxnRef] = React.useState('')
    // credit/debit BFC Points 
    const [openCheckerModal, setOpenCheckerModal] = React.useState(false);
    const [openMakerModal, setOpenMakerModal] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState('Credit');
    const [bfcPoints, setBfcPoints] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [error, setError] = React.useState('');
    const [getCDBfcpointsData, setGetCDBfcpointsData] = React.useState([]);
    const [CDBfcpointsLastData, setCDBfcpointsLastData] = React.useState({});


    const [bfcpModalTitle, setBfcpModalTitle] = React.useState(false)
    const [bfcpId, setBfcpId] = React.useState('')
    const [remarksModal, setRemarksModal] = React.useState(false)
    const [remarks, setRemarks] = React.useState('');
    const [statusBfcp, setStatusBfcp] = React.useState('');






    React.useEffect(() => {
        // console.log(props);
        if (props.id !== null) { getCustomerDetails(props.id) }
    }, [props.id]);


    let service = users.services.find(service => service.name == "Customers")
    let group = users.groups.find(group => group.groupName == "Customer Service")


    const getCustomerDetails = async (id) => {
        //function to fetch customer details
        axios.get(`${config.url().baseURL}/get-user-details/${id}`).then((resp) => {
            let respData = resp.data;
            setUserID(id)
            // console.log("userID", id)
            if (!respData.err) {

                setUserDetails(respData.data)
                if (respData.data.KYC.PersonPhotoImg) {

                    respData.data.KYC.AddressProofDocImg.push(respData.data.KYC.PersonPhotoImg)

                }
                if (respData.data.KYC && respData.data.KYC.AddressProofDocImg && respData.data.KYC.AddressProofDocImg[0]) {
                    let extension = respData.data.KYC.AddressProofDocImg[0].substring(respData.data.KYC.AddressProofDocImg[0].indexOf("/") + 1, respData.data.KYC.AddressProofDocImg[0].indexOf(";base64"));
                    setThumbnail(extension)
                    setDocumentViewerData(respData.data.KYC.AddressProofDocImg[0])
                }
                setShowDetails(true)
            } else {
                setUserDetails([])
                setShowDetails(false)
                props.snack(respData.msg)
                props.back()
            }
        }).catch((err) => {
            setUserDetails([])
            setShowDetails(false)
            props.snack("Unable fetch user details")
            props.back()
        });
    }
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const openFeeReversal = (id) => {
        setTxnRef(id)
        setShowFeeReversal(true);
    }

    const closeModal = () => {
        setShowFeeReversal(false);
        setShowFeeLog(false)
    }

    const closeDetails = () => {
        setUserDetails([])
        setShowDetails(false)
        props.back()
    }

    const feeLog = (id) => {
        setShowFeeLog(true);
        setTxnRef(id)
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const viewIDProof = (image) => {
        //function to open ID Proof
        let extension = image.substring(image.indexOf("/") + 1, image.indexOf(";base64"));
        setDocumentViewerData(image)
        if (extension == "pdf") {
            setPageNumber(1)
            setOpenPDFViewer(true)
            setOpenImageViewer(false)
        } else {
            setOpenImageViewer(true)
            setOpenPDFViewer(false)
        }
    }
    const feeReversalStatusLog = (txRef) => {
        try {
            axios.get(`${config.url().baseURL}/get-feeReversalLogsV2/${txRef}`)
            .then((resp) => {
                let respData = resp?.data;
                if (!respData.err) {
                    setFeeReversalLog(respData.data);
                    setBfcpModalTitle(true);
                    setFeeReversalLogModal(true);
                } else {
                    openSweetAlert("No Logs Found");
                    console.error("bfcpoints-log error:", respData.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        
        } catch (err) {
            console.log(err);
        }
    }
    const unlockUser = (id) => {
        //function to unlock user
        try {
            axios.post(`${config.url().baseURL}/unlock-user/${id}`)
                .then(async (resp) => {
                    if (!resp.data.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="User unlocked."
                                onConfirm={() => { setRespAlert(null); getCustomerDetails(user.Middleware.userID) }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        )
                    } else props.snack(resp.data.msg);
                })
                .catch((err) => {
                    console.log(err);
                    props.snack("Unable to unlock user.");
                });
        } catch (error) {
            props.snack("Unable to unlock user.")

        }
    }
    const getLogBlock = (cardID) => {
        try {
            // setViewLog(false);
            axios.get(`${config.url().baseURL}/get-card-block-logs/${cardID}`).then(async (res) => {
                if (!res.data.err) {
                    setLogBlock(res.data.data);
                }
                else {
                    props.snack(res.data.msg);
                    setViewLog(false);
                }
            }
            ).catch((err) => {
                props.snack("No Block Card Log is available")
                setViewLog(false);
            })


        }
        catch (err) {
            props.snack("No Block card Details")
        }
    }
    const lockUser = (id) => {
        //function to lock user
        try {
            axios.post(`${config.url().baseURL}/lock-user`, { userID: id })
                .then(async (resp) => {
                    if (!resp.data.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="User locked."
                                onConfirm={() => { setRespAlert(null); getCustomerDetails(user.Middleware.userID) }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        )
                    } else props.snack(resp.data.msg);
                })
                .catch((err) => {
                    console.log(err);
                    props.snack("Unable to lock user.");
                });
        } catch (error) {
            props.snack("Unable to lock user.")

        }
    }
    const confirmBlockCard = (cpr, id, cardStatus, userId) => {
        const { value: reasonForBlocking } = Swal.fire({
            title: 'Select the Reason',
            input: 'select',
            inputOptions: {
                // reason: {
                LOST_CARD: 'LOST CARD',
                STOLEN_CARD: 'STOLEN CARD',
                NOT_DELIVERED: 'NOT DELIVERED',
                FRAUD: 'FRAUD',
                NOT_RECEIVED: 'NOT RECEIVED',
                INVALID_ACCOUNTNUMBER: 'INVALID ACCOUNTNUMBER',
                OTHER: 'OTHER'
                // },
            },
            inputValidator: (value) => {
                return new Promise((resolve, reject) => {
                    resolve();

                })
            },
            inputPlaceholder: 'Select the reason',
            showCancelButton: true,
            showConfirmButton: true,

        })
            .then((result) => {
                if (result.isConfirmed) {
                    blockCard(cpr, id, cardStatus, result.value, userId)
                }
            })
        // 
    }
    const blockCard = (cpr, id, cardStatus, reasonForBlocking, userId) => {
        try {
            axios.post(`${config.url().baseURL}/update-card-status/${cpr}`, { CardId: id, CardStatus: cardStatus, ReasonCode: reasonForBlocking }).
                then(async (resp) => {

                    if (!resp.data.err) {
                        await getCustomerDetails(userId);

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Successfully Blocked the Card.',
                            showConfirmButton: false,
                            timer: 1500
                        })

                    } else props.snack(resp.data.msg);
                }).catch((err) => {
                    console.log(err);
                    props.snack("Unable to blok the card.");
                });

        }
        catch (err) {
            console.log(err);
            props.snack("Unable to block the card");
        }
    }
    const confirmUnlock = (id) => {
        //function to confirm unlock
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure ?"
                onConfirm={() => { setRespAlert(null); unlockUser(id) }}
                confirmBtnCssClass={classes.button + " " + classes.success}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
            >
                Do you really want to unlock this user ?
            </SweetAlert>
        )
    }

    const confirmLock = (id) => {
        //function to confirm lock
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure ?"
                onConfirm={() => { setRespAlert(null); lockUser(id) }}
                confirmBtnCssClass={classes.button + " " + classes.danger}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
            >
                Do you really want to lock this user ?
            </SweetAlert>
        )
    }
    const confirmDormant = (id) => {
        //confirm dormant
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure ?"
                onConfirm={() => { setRespAlert(null); changeDormant(id, user.Ledger && user.Ledger.accounts && user.Ledger.accounts[0].status == "ACTIVE" ? "DORMANT" : "ACTIVE") }}
                confirmBtnCssClass={classes.button + " " + classes.danger}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
            >
                Do you really want make this user {user.Ledger && user.Ledger.accounts && user.Ledger.accounts[0].status == "ACTIVE" ? "Dormant" : "Active"} ?
            </SweetAlert>
        )
    }
    const changeDormant = (id, status) => {
        //function to change account status of a customer
        try {
            axios.post(`${config.url().baseURL}/update-cust-account/${id}`, { status: status })
                .then(async (resp) => {
                    if (!resp.data.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={resp.data.msg}
                                onConfirm={() => { setRespAlert(null); getCustomerDetails(user.Middleware.userID) }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        )
                    } else props.snack(resp.data.msg);
                })
                .catch((err) => {
                    console.log(err);
                    props.snack("Unable to change user account status.");
                });
        } catch (error) {
            props.snack("Unable to change user account status.");
        }
    }
    const downloadCustomerDetails = (cpr) => {
        //function to download customer details pdf 
        setSubmitting(true)
        try {
            axios.get(`${config.url().baseURL}/cust-indv-kyc-download/${cpr}`, {
                responseType: "blob",
            }).then(async (resp) => {
                if (!resp.data.err) download(resp.data, `${cpr}.pdf`);
                else props.snack(resp.data.msg)
                setSubmitting(false)
            }).catch((err) => {
                setSubmitting(false)
                console.log(err);
                props.snack("Unable to generate pdf..");
            });
        } catch (error) {
            setSubmitting(false)
            props.snack("Unable to generate pdf.");
        }
    }

    const downloadDoc = (doc, name) => {
        //function to download  doc
        download(libs.base64ToFile(doc, name), name)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const handleFeeReversal = (cpr) => {
        try {
            let data = {
                cpr: cpr
            };
            axios.post(`${config.url().transactionMSURL}/mc/v4/cxportal/getFeeReversalDetails`, data)

                .then((resp) => {
                    let respData = resp?.data?.data?.feeReversals;
                    if (!respData.err) {
                        setFeeReversals(respData);
                        setOnBoardingLogModal(true);
                    } else {
                        console.error("Fee reversal error:", respData.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    // CREDIT DEBIT BFC POINTS FUNCTIONS 

    const handleClose = () => {
        setOpenCheckerModal(false);
        setOpenMakerModal(false)
        setRemarksModal(false)
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleBfcPointsChange = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setBfcPoints(value);
            setError('');
        } else {
            setError('Please enter only numeric digits.');
        }
    };

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };
    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
    };

    const handleCDBfcpointsData = () => {
        try {
            axios.get(`${config.url().baseURL}/get-cd-bfcpoints-data/${user.Middleware.userID}`)

                .then((resp) => {
                    let respData = resp?.data;
                    if (!respData.err) {
                        setGetCDBfcpointsData(respData?.data);
                        setCDBfcpointsLastData(respData?.lastData)
                        if (service.type === "maker") {
                            setOpenCheckerModal(true)

                            if (respData === undefined || service.type === "maker" && !respData.data[0].status === "APPROVED" || service.type === "maker" && respData.data[0].status === "RECHECKED") {
                            }
                        }
                        if (service.type === "checker") setOpenCheckerModal(true)
                    } else {
                        if (service.type === "maker") setOpenMakerModal(true)
                        if (service.type === "checker") openSweetAlert('No request available')

                        console.error("bfcpoints-data error:", respData.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const createCreditOrDebitBfcPointsRequest = async (status) => {
        try {
            const formData = {
                id: bfcpId || null,
                userID: user.Middleware.userID,
                selectedOption,
                bfcPoints,
                reason,
                status
            };

            const response = await axios.post(`${config.url().baseURL}/create-credit-debit-bfc-points`, formData);
            const respData = response.data;

            handleClose();
            setBfcPoints('');
            setReason('');

            if (!respData.err) {
                if (bfcPoints.trim() === '' || reason.trim() === '') openSweetAlert('Please pass the values')

                setRespAlert(
                    <SweetAlert
                        success
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                            setRespAlert(null);
                        }}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                    />
                );
            } else {
                setRespAlert(
                    <SweetAlert
                        danger
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                            setRespAlert(null);
                        }}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                    />
                );
            }
        } catch (error) {
            openSnackBar("Unable to process.");
        }
    };

    const approveCreditOrDebitBfcPointsRequest = (id, userID, selectedOption, bfcPoints, reason, status) => {
        try {
            const formData = {
                id,
                userID,
                selectedOption,
                bfcPoints,
                reason,
                status,
                remarks
            };
            axios.post(`${config.url().baseURL}/approve-credit-debit-bfc-points`, formData)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        handleClose()
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setRespAlert(null)
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                        setBfcPoints('')
                        setReason('')
                    } else {
                        handleClose()
                        setBfcPoints('')
                        setReason('')
                        setRespAlert(
                            <SweetAlert
                                danger
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setRespAlert(null)
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.danger}
                            />
                        )
                    }
                }).catch((err) => {
                    openSnackBar("Unable to process.");
                });
        } catch (error) {

        }
    }
    const addRemarksBfcPoints = (remarks) => {
        try {

            let data = {
                id: bfcpId,
                remarks,
            }
            axios.post(`${config.url().baseURL}/add-remarks-bfcp`, data)
                .then((resp) => {
                    let respData = resp?.data?.data;
                    if (!respData.err) {
                        handleClose()
                        if (remarks.trim() === '') openSweetAlert('Please pass the values')
                        else approveCreditOrDebitBfcPointsRequest(respData._id, respData.userID, respData.selectedOption, respData.bfcPoints, respData.reason, statusBfcp)
                    } else {
                        console.error("bfcpoints-data error:", respData.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    }
    const openSweetAlert = (msg) => {
        handleClose()
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title={msg}
                onConfirm={() => {
                    setRespAlert(null)
                }}
                confirmBtnCssClass={classes.button + " " + classes.warning}
            />
        )
    }

    const getCreditDebitBfcPointsLogs = () => {
        try {
            axios.get(`${config.url().baseURL}/get-credit-debit-bfcPoints-logs/${user.Middleware.userID}`)

                .then((resp) => {
                    let respData = resp?.data;
                    if (!respData.err) {
                        setFeeReversalLog(respData.data);
                        setBfcpModalTitle(true)
                        setFeeReversalLogModal(true);
                    } else {
                        openSweetAlert("No Logs Found")
                        console.error("bfcpoints-log error:", respData.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            {respAlert}
            <Collapse
                in={!showDetails}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>

            <Collapse in={showDetails} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardBody>
                        <br />   <br />
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <div style={{ textAlign: "center", overflow: "hidden" }}>
                                    {
                                        user.KYC && user.KYC.AddressProofDocImg && user.KYC.AddressProofDocImg.length ?
                                            (user.KYC.AddressProofDocImg || []).map((doc, key) => {
                                                let extension = doc.substring(doc.indexOf("/") + 1, doc.indexOf(";base64"));
                                                if (extension == "pdf") {
                                                    return (
                                                        <>
                                                            <Document
                                                                style={{ width: "100px" }}
                                                                className={classes.customLink}
                                                                onClick={() => viewIDProof(doc)}
                                                                file={documentViewerData}
                                                                onLoadSuccess={onDocumentLoadSuccess}
                                                            >
                                                                <Page pageNumber={pageNumberTwo} />
                                                            </Document>
                                                            <p>Page {pageNumberTwo} of {numPages}</p>
                                                            <Button size="sm" color="primary" onClick={() => {
                                                                if (pageNumberTwo < 2) {
                                                                    setPageNumberTwo(1)
                                                                } else {
                                                                    let newPageNumber = pageNumberTwo - 1
                                                                    setPageNumberTwo(newPageNumber)
                                                                }
                                                            }} >Pervious</Button>
                                                            <Button size="sm" color="primary"
                                                                onClick={() => {
                                                                    if (numPages > pageNumber) {
                                                                        let newPageNumber = pageNumberTwo + 1
                                                                        setPageNumberTwo(newPageNumber)
                                                                    } else {
                                                                        setPageNumberTwo(numPages)
                                                                    }
                                                                }}
                                                            >Next</Button>
                                                            <br />
                                                            <Button onClick={() => downloadDoc(documentViewerData, `${user.KYC.EmployeeCPR}.${thumbnail}`)} size="md" simple color="primary"> <DownloadIcon /> </Button>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <img
                                                                onClick={() => viewIDProof(doc)}
                                                                src={doc}
                                                                alt="..."
                                                                className={
                                                                    classes.imgRaised +
                                                                    " " +
                                                                    classes.imgRounded +
                                                                    " " +
                                                                    classes.imgFluid +
                                                                    " " +
                                                                    classes.customLink
                                                                } />
                                                            <br />
                                                            <Button onClick={() => downloadDoc(documentViewerData, `${user.KYC.EmployeeCPR}.${thumbnail}`)} size="md" simple color="primary"> <DownloadIcon /> </Button>
                                                        </>)
                                                }
                                            })
                                            :
                                            <img
                                                src={avatar}
                                                alt="..."
                                                className={
                                                    classes.imgRaised +
                                                    " " +
                                                    classes.imgRounded +
                                                    " " +
                                                    classes.imgFluid
                                                }
                                            />


                                    }
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={9}>

                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h4 className={classes.noMargin} ><b>{user.KYC && user.KYC.FirstNameEnglish ? user.KYC.FirstNameEnglish : null} {user.KYC && user.KYC.MiddleName1English ? user.KYC.MiddleName1English : null} {user.KYC && user.KYC.LastNameEnglish ? user.KYC.LastNameEnglish : null} </b></h4>
                                        <div style={{ float: "right" }}>{submitting ? <MoonLoader color="#004fa4" loading={true} css={overrideNew} size={25} /> : <Button style={{ margin: "0px", marginBottom: "-10px" }} onClick={() => { downloadCustomerDetails(user.KYC.EmployeeCPR) }} size="md" simple color="primary"> Download User Details</Button>}</div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    {user.KYC && user.KYC.NameOfEmployer ?
                                        (
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Corporate Name : </span> {user.KYC.NameOfEmployer}</h5>
                                            </GridItem>
                                        ) : null}
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> CPR : </span>  {user.KYC && user.KYC.EmployeeCPR ? user.KYC.EmployeeCPR : (user.Middleware && user.Middleware.cpr ? user.Middleware.cpr : null)} </h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Mobile : </span> {user.KYC && user.KYC.Contact && user.KYC.Contact.PhoneNumber ? user.KYC.Contact.PhoneNumber : (user.Middleware && user.Middleware.mobile ? user.Middleware.mobile : null)} </h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Passport No : </span> {user.KYC && user.KYC.PassPortNo ? user.KYC.PassPortNo : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> CPR Expiry : </span>
                                            {user.KYC && user.KYC.EmployeeCRPExpiry ?
                                                (moment(user.KYC.EmployeeCRPExpiry, ["DD-MM-YYYY", "DD/MM/YYYY", 'MM-DD-YYYY', "MM/DD/YYYY", "YYYY-MM-DD", "YYYY/MM/DD", "YYYY-DD-MM", "YYYY/DD/MM", "DD-MMM-YYYY", "DD/MMM/YYYY"]).format("DD-MMM-YYYY") !== "Invalid date" ?
                                                    moment(user.KYC.EmployeeCRPExpiry, ["DD-MM-YYYY", "DD/MM/YYYY", 'MM-DD-YYYY', "MM/DD/YYYY", "YYYY-MM-DD", "YYYY/MM/DD", "YYYY-DD-MM", "YYYY/DD/MM", "DD-MMM-YYYY", "DD/MMM/YYYY"]).format("DD-MMM-YYYY") : user.KYC.EmployeeCRPExpiry)
                                                : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}>
                                            <span className={classes.mdBold}> Date Of Birth : </span>
                                            {user.KYC && user.KYC.DateOfBirth ? (moment(user.KYC.DateOfBirth, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD']).format("DD-MMM-YYYY") !== "Invalid date" ?
                                                moment(user.KYC.DateOfBirth, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD']).format("DD-MMM-YYYY") : user.KYC.DateOfBirth)
                                                : (user.Middleware && user.Middleware.dateOfBirth ?
                                                    (moment(user.Middleware.dateOfBirth, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD']).format("DD-MMM-YYYY") !== "Invalid date" ?
                                                        moment(user.Middleware.dateOfBirth, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD']).format("DD-MMM-YYYY") : user.Middleware.dateOfBirth)
                                                    : null)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}>
                                            <span className={classes.mdBold}> Passport Expiry : </span>
                                            {/* {user.KYC && user.KYC.PassportExpiry ?
                                                (moment(new Date(user.KYC.PassportExpiry)).format("DD-MM-YYYY") !== "Invalid date" ?
                                                    moment(new Date(user.KYC.PassportExpiry)).format("DD-MM-YYYY") : user.KYC.PassportExpiry) : null} */}
                                            {user.KYC && user.KYC.PassportExpiry ? (moment(user.KYC.PassportExpiry, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD']).format("DD-MMM-YYYY") !== "Invalid date" ?
                                                moment(user.KYC.PassportExpiry, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD']).format("DD-MMM-YYYY") : (user.KYC.PassportExpiry == "Invalid date" ? "" : user.KYC.PassportExpiry))
                                                : null}
                                        </h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Language Preferred : </span>  {user.Middleware && user.Middleware.languagePref ? user.Middleware.languagePref : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Nationality : </span>  {user.KYC && user.KYC.Nationality ? user.KYC.Nationality : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> User Type : </span>  {user.Middleware && user.Middleware.userType ? user.Middleware.userType : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Created On  : </span>{user.Middleware && user.Middleware.created_at ? moment(user.Middleware.created_at).format("DD/MM/YYYY hh:mm A") : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Updated On  :</span> {user.Middleware && user.Middleware.updatedAt ? moment(user.Middleware.updatedAt).format("DD/MM/YYYY hh:mm A") : null}</h5>
                                    </GridItem>  <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Mobile Activation Date  :</span> {user.Middleware && user.Middleware.mobile_registered_on ? moment(user.Middleware.mobile_registered_on).format("DD/MM/YYYY hh:mm A") : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Email  :</span> {user.KYC && user.KYC.Contact && user.KYC.Contact.Email ? user.KYC.Contact.Email : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Occupation  :</span> {user.KYC && user.KYC.Occupation ? user.KYC.Occupation : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <br />   <br />
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Onboarded IP Location  :</span> {user.locationData && user.locationData.country ? user.locationData.country : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Onboarded IP Latitude  :</span> {user.locationData && user.locationData?.additionalInfo?.loc ? user.locationData.additionalInfo.loc.split(",")[0] : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Onboarded IP Longitude  :</span> {user.locationData && user.locationData?.additionalInfo?.loc ? user.locationData.additionalInfo.loc.split(",")[1] : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <br />   <br />
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Account Balance  : </span>  {user.Ledger && user.Ledger.accounts && (user.Ledger.accounts[0].balance || user.Ledger.accounts[0].balance == 0) ? (user.Ledger.accounts[0].balance / 1000).toFixed(3) : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Wallet Type  : </span>  {user.Ledger && user.Ledger.accounts && user.Ledger.accounts[0].accountCategory ? user.Ledger.accounts[0].accountCategory : null}</h5>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Wallet Account No  : </span>  {user.Ledger && user.Ledger.accounts && user.Ledger.accounts[0].number ? user.Ledger.accounts[0].number : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Reward Balance Points :  </span>  {user.flyyData && user.flyyData.totalPoints ? user.flyyData.totalPoints : 0}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Chip
                                            size="medium"
                                            style={{ backgroundColor: '#D9D9D9', color: 'black', margin: '5px', cursor: 'pointer' }}
                                            onClick={handleCDBfcpointsData}
                                            label="Credit/Debit BFC Points"
                                        />
                                        <Tooltip title="BFC Points Log">
                                            <span
                                                style={{
                                                    backgroundColor: '#D9D9D9',
                                                    color: 'black',
                                                    margin: '5px',
                                                    cursor: 'pointer',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    padding: '0 8px',
                                                    borderRadius: '16px',
                                                    height: '32px',
                                                    lineHeight: '32px',
                                                }}
                                                onClick={getCreditDebitBfcPointsLogs}
                                            >
                                                <TimelineIcon />
                                            </span>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Referral ID : </span>{user.mcReferralData && user.mcReferralData.referralId ? user.mcReferralData.referralId : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Cashback to be given to referee  : </span> {user.mcReferralData && user.mcReferralData.cashbackGivenToReferee ? user.mcReferralData.cashbackGivenToReferee : null}</h5>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Cashback to be given to referrer: </span>{user.mcReferralData && user.mcReferralData.cashbackGivenToReferrer ? user.mcReferralData.cashbackGivenToReferrer : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Campaign Name:  </span> {user.mcReferralData && user.mcReferralData.campaignName ? user.mcReferralData.campaignName : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>

                                    {user && user.mcCard && user.mcCard.productType !== null && user.mcCard.productType !== "NO CARD" && Object.keys(user.mcCard).length !== 0 ? (
                                        <GridItem xs={12} sm={12} md={4}>
                                            <h5 className={classes.mdMargin}>
                                                <span className={classes.mdBold}>Multicurrency User Type: </span>
                                                {user.mcCard.productType === "TravelBuddy" ? (
                                                    <Chip size="small" style={{ backgroundColor: "blue", color: "white" }} label={user.mcCard.productType} />
                                                ) : (
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            <p style={{ fontSize: "11px" }}>
                                                                Customer has at least one {user.mcCard.productType === "TravelBuddyPlus" ? "Travel Buddy Plus" : user.mcCard.productType} Card, check in CX Multicurrency Card Report Tab and search with card creation date
                                                            </p>
                                                        }
                                                    >
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Chip size="small" style={{ backgroundColor: "blue", color: "white" }} label={user.mcCard.productType} />
                                                            <InfoRoundedIcon style={{ marginRight: "4px", color: "grey" }} />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </h5>
                                        </GridItem>
                                    ) : null}

                                    {((service.type === "checker" || service.type === "maker" || service.type === "approver") &&
                                        (user?.mcCard?.productType === "TravelBuddyPlus" || user?.mcCard?.productType === "TravelBuddy")) ? (
                                        <GridItem>
                                            <Chip size="medium" style={{ backgroundColor: "#004fa4", color: "white", margin: "15px", cursor: "pointer" }} onClick={() => {
                                                handleFeeReversal(user?.KYC?.EmployeeCPR)
                                            }} label="Fee Reversal" />
                                        </GridItem>
                                    ) : null}




                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>

                                    <br />   <br />
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>API Version  : </span>  {user.Middleware && user.Middleware.v_API ? user.Middleware.v_API : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>APP Version  : </span>  {user.Middleware && user.Middleware.v_APP ? user.Middleware.v_APP : null}</h5>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> User ID  : </span>  {user.Middleware && user.Middleware.userID ? user.Middleware.userID : null}</h5>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Device ID  : </span> {user.Middleware && user.Middleware.deviceID ? user.Middleware.deviceID : null}</h5>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>CleaverTap ID  : </span> {user.Middleware && user.Middleware.cleverTapID ? user.Middleware.cleverTapID : null}</h5>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Shufti Reference  : </span> {user.Middleware && user.Middleware.refShufti ? user.Middleware.refShufti : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>

                                    <br />   <br />
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Additional Data Status  : </span>  {user.Middleware && user.Middleware.additionalDataStatus ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)} </h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> Shufti status:  </span> {user.Middleware && user.Middleware.statusShufti ? user.Middleware.statusShufti : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}> IGA Status  : </span> {user.Middleware && user.Middleware.statusIGA ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Netsys Status  : </span> {user.Middleware && user.Middleware.statusNetsys ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>User Status  : </span> {user.Middleware && user.Middleware.statusUser ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>User Type  : </span> {user.Middleware && user.Middleware.userType ? user.Middleware.userType : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Lock Status  : </span> {user.Middleware && (user.Middleware.statusMPIN.lockUser || user.Middleware.statusDOB.lockUser || user.Middleware.statusOTP.lockUser) ? <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="Locked" /> : <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Unlocked" />}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Dormant Status  : </span> {user.Ledger && user.Ledger.accounts && user.Ledger.accounts[0].status ? user.Ledger.accounts[0].status : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Compliance Status : </span> {user.Middleware && user.Middleware.statusCompliance ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Freeze Status : </span> {user.Middleware && user.Middleware.statusFreezed.status ? (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="Freezed" />) : (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Unfrozen" />)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Freezed By : </span> {user.Middleware && user.Middleware.statusFreezed && user.Middleware.statusFreezed.user ? user.Middleware.statusFreezed.user : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Freeze Status Message : </span> {user.Middleware && user.Middleware.statusFreezed.remarks ? user.Middleware.statusFreezed.remarks : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Freeze Remarks : </span> {user.Middleware && user.Middleware.statusFreezed.description ? user.Middleware.statusFreezed.description : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Block Status : </span> {user.Middleware && user.Middleware.statusBlocked.status ? (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="Blocked" />) : (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Unblocked" />)}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Blocked By : </span> {user.Middleware && user.Middleware.statusBlocked && user.Middleware.statusBlocked.user ? user.Middleware.statusBlocked.user : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Block Status_Message : </span> {user.Middleware && user.Middleware.statusBlocked && user.Middleware.statusBlocked.description ? user.Middleware.statusBlocked.description : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Block Remarks : </span> {user.Middleware && user.Middleware.statusBlocked && user.Middleware.statusBlocked.remarks ? user.Middleware.statusBlocked.remarks : null}</h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                    </GridItem>
                                    {user.Cards && user.Cards.length ?
                                        <>
                                            <br />   <br />
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5 className={classes.smMargin} >Cards</h5>
                                                <Divider />
                                            </GridItem>
                                            {/* <GridItem xs={12} sm={12} md={12}> */}
                                            <br />
                                            {
                                                user.Cards.map((card, key) => {
                                                    delete card["cardExpiryDate"]
                                                    delete card["cardMaskNumber"]
                                                    return (
                                                        <>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Divider />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card ID : </span> {card.cardId}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Status : </span> {card.cardStatus == "NORMAL" ? (<Chip size="small" style={{ backdColor: "green", color: "white" }} label={card.cardStatus} />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label={card.cardStatus} />)}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Delivery Status : </span> {card.deliveryCardFlag == "Y" ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="DELIVERED" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="NOT DELIVERED" />)}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Activation Status : </span> {card.activationFlag == "Y" ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="ACTIVATED" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="NOT ACTIVATED" />)}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>&nbsp;
                                                                {card.cardStatus == "NORMAL" ?
                                                                    <Button size="sm" onClick={() => confirmBlockCard(`${user.KYC.EmployeeCPR}`, card.cardId, "SUSPENDED", `${user.Middleware.userID}`)} color="danger">Block Card</Button> : null}
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Button size="sm" color="primary" onClick={() => { getLogBlock(card.cardId); setViewLog(!viewLog); }} variant="contained">{viewLog ? "Hide Log" : "View Log"}</Button>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Divider />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Accordion expanded={expanded === key} onChange={handleChange(key)}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                    >
                                                                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.mdBold}>
                                                                            Card Details
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <JSONPretty data={card} />
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Divider />
                                                            </GridItem>
                                                        </>
                                                    )
                                                }
                                                )
                                            }
                                            {/* </GridItem> */}
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Collapse
                                                    in={viewLog}
                                                    style={{ transformOrigin: "0 0 0" }}
                                                    {...(true ? { timeout: 500 } : {})}>
                                                    <div className={classes.logContainer}>
                                                        {(logBlock) ?
                                                            <>
                                                                <Accordion  >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content" card
                                                                        id="panel1a-header">
                                                                        <Typography>{"Block Card Logs"}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails  >
                                                                        <div style={{ width: "750px !important" }}>
                                                                            <JSONPretty data={logBlock} />
                                                                        </div>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </> : ""
                                                        }</div>
                                                </Collapse>
                                            </GridItem>

                                        </>
                                        : null
                                    }
                                    {user.tabibCards ?
                                        <>
                                            <br />   <br />
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5 className={classes.smMargin} >Tabiib Cards</h5>
                                                <Divider />
                                            </GridItem>
                                            {/* <GridItem xs={12} sm={12} md={12}> */}
                                            <br />
                                            {

                                                <>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Divider />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card No : </span> {user.tabibCards.cardNo}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Type : </span> {user.tabibCards.cardType == "B2C" ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label={user.tabibCards.cardType} />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label={user.tabibCards.cardType} />)}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Reference No : </span> {user.tabibCards.cardRefNo}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Activation Status : </span><Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="ACTIVATED" /></h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}>Created At:</span> {moment(user.tabibCards.createdAt).format("DD-MM-YY hh:mm:ss")}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Divider />
                                                    </GridItem>
                                                    {user.tabibCards.familyMembers.length != 0 ?
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <Accordion expanded={expanded === user.tabibCards._id} onChange={handleChange(user.tabibCards._id)}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.mdBold}>
                                                                        Family Member Card Details
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <JSONPretty data={user.tabibCards.familyMembers} />
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Divider />
                                                        </GridItem> : null
                                                    }
                                                </>
                                            }

                                        </>
                                        : null
                                    }
                                    {user.mdcHealthCard ?
                                        <>
                                            <br />   <br />
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5 className={classes.smMargin} >MDC Health Cards</h5>
                                                <Divider />
                                            </GridItem>
                                            {/* <GridItem xs={12} sm={12} md={12}> */}
                                            <br />
                                            {

                                                <>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Divider />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Applied CPR : </span> {user.mdcHealthCard.appliedCpr}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Type : </span> {user.mdcHealthCard.mdcRegistrationData.cardType == "INDIVIDUAL" ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label={user.mdcHealthCard.mdcRegistrationData.cardType} />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label={user.mdcHealthCard.mdcRegistrationData.cardType} />)}</h5>
                                                    </GridItem>
                                                    {/* <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Reference No : </span> {user.mdcHealthCard.cardRefNo}</h5>
                                                    </GridItem> */}
                                                    {/* <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Activation Status : </span><Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="ACTIVATED" /></h5>
                                                    </GridItem> */}
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <h5 className={classes.mdMargin}><span className={classes.mdBold}>Created At:</span> {moment(user.mdcHealthCard.createdAt).format("DD-MM-YY hh:mm:ss")}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Divider />
                                                    </GridItem>
                                                    {/* {user.tabibCards.familyMembers.length != 0 ?
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <Accordion expanded={expanded === user.tabibCards._id} onChange={handleChange(user.tabibCards._id)}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.mdBold}>
                                                                        Family Member Card Details
                                                                    </Typography>
                                                                                                                                   </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <JSONPretty data={user.tabibCards.familyMembers} />
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Divider />
                                                        </GridItem> : null
                                                    } */}
                                                </>
                                            }

                                        </>
                                        : null
                                    }
                                    {user?.mcCard?.cards && user?.mcCard?.cards.length ?
                                        <>
                                            <br />   <br />
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5 className={classes.smMargin} >MultiCurrency Cards</h5>
                                                <Divider />
                                            </GridItem>
                                            {/* <GridItem xs={12} sm={12} md={12}> */}
                                            <br />
                                            {
                                                user.mcCard?.cards?.map((card, key) => {
                                                    return (
                                                        <>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Divider />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card No : </span> {card.cardNumber}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Expiry Date : </span> {card.expiryDate}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Type: </span> {card.cardType == "PHYSICAL" ? (<Chip size="small" style={{ backgroundColor: "blue", color: "white" }} label={card.cardType} />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label={card.cardType} />)}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card Status : </span> {card.cardStatus == "BLOCKED" ? (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label={card.cardStatus} />) : (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label={card.cardStatus} />)}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Card kitNo : </span>{card.kitNo}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}>
                                                                    <span className={classes.mdBold}>Card Creation Date: </span>
                                                                    {new Date(card.createdAt).toLocaleDateString('en-GB', {
                                                                        day: '2-digit',
                                                                        month: 'short',
                                                                        year: 'numeric'
                                                                    })}
                                                                </h5>
                                                            </GridItem>

                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Divider />
                                                            </GridItem>

                                                        </>)
                                                })}

                                        </>
                                        : null
                                    }

                                    {user?.userDevices && user.userDevices.err == false ?
                                        <>
                                            <br />   <br />
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5 className={classes.smMargin} >User Devices</h5>
                                                <Divider />
                                            </GridItem>
                                           
                                            <br />
                                            <>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Divider />
                                                    <h5 className={classes.smMargin} >Current Device</h5>
                                                    <Divider />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Brand: </span> {user?.userDevices?.data?.activeDeviceDetails.brand}</h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>device: </span> {user?.userDevices?.data?.activeDeviceDetails.device}</h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>device ID: </span> {user?.userDevices?.data?.activeDeviceDetails.deviceID}</h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Model: </span> {user?.userDevices?.data?.activeDeviceDetails.model}</h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>OS version: </span> {user?.userDevices?.data?.activeDeviceDetails.osVersion}</h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Binded Date: </span> {moment(user?.userDevices?.data?.latestBinding).format("DD-MM-YYYY, h:mm:ss a")}</h5>                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Device Status : </span> <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label={"Active"} /></h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Divider />
                                                </GridItem>
                                            </>
                                            <>
                                                <h5 className={classes.smMargin} >Previous Devices</h5>
                                                <Divider />
                                            </>
                                            {
                                                user.userDevices?.data?.previousDevices?.map((item, key) => {
                                                    return (
                                                        <>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Divider />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Brand: </span> {item.brand}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>device: </span> {item.device}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>device ID: </span> {item.deviceID}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Model: </span> {item.model}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>OS version :</span> {item.osVersion}</h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <h5 className={classes.mdMargin}>
                                                                    <span className={classes.mdBold}>Binded Date: </span>
                                                                    {moment(item.createdAt).format("DD-MM-YYYY, h:mm:ss a")}
                                                                </h5></GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <h5 className={classes.mdMargin}> <span className={classes.mdBold}>Device Status : </span> <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label={"In-Active"} /></h5>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Divider />
                                                            </GridItem>

                                                        </>)
                                                })}

                                        </>
                                        : null
                                    }
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <br />   <br />
                                <div style={{ textAlign: "center" }}>

                                    <Button onClick={closeDetails} size="md" simple color="primary"> <ArrowBackIcon />Back </Button>  &nbsp;
                                    {/* {user.Ledger && user.Ledger.accounts && user.Ledger.accounts[0].status == "ACTIVE" ? <Button size="sm" color="warning" onClick={() => { confirmDormant(user.KYC.EmployeeCPR); }}>Make Dormant </Button> : <Button size="sm" color="success" onClick={() => { confirmDormant(user.KYC.EmployeeCPR); }}>Make Active </Button>} */}
                                    {
                                        (service.type !== "viewer" || (group !== undefined && group.groupName === "Customer Service")) && (
                                            <>
                                                {props.disableLock ? null : user.Middleware && (
                                                    user.Middleware.statusMPIN.lockUser ||
                                                    user.Middleware.statusDOB.lockUser ||
                                                    user.Middleware.statusOTP.lockUser ||
                                                    user.Middleware.statusPassport.lockUser
                                                ) ? (
                                                    <>
                                                        <Button size="sm" onClick={() => confirmUnlock(user.Middleware.userID)} color="success">
                                                            Unlock User
                                                        </Button>
                                                        &nbsp;
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button size="sm" onClick={() => confirmLock(user.Middleware.userID)} color="danger">
                                                            Lock User
                                                        </Button>
                                                        &nbsp;
                                                    </>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </Collapse >
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={documentViewerData}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <Button size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</Button>
                    <Button size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</Button>
                </DialogContent>
            </Dialog>

            {showLog ? <FeeReversalLog id={txnRef} openSnackBar={openSnackBar} close={closeModal} /> : ""}
            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.modal, }}
                open={showFeeReversal}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <FeeReversal txnRef={txnRef} amount={amount} userID={userID} close={closeModal} userType={service.type} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>
            <Dialog
                open={onBoardingLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setOnBoardingLogModal(false);
                    setOnBoardingLogs([]);
                }}
                maxWidth="md"
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <h4 style={{ fontFamily: 'sans-serif' }}>Travel Buddy  Fee Reversal</h4>
                </DialogTitle>

                <DialogContent>
                    <TableContainer >
                        <MuiTable>
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column._id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feeReversals?.map((feeReversal, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>

                                            <TableCell>
                                                {moment(feeReversal.time).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {feeReversal.amount}
                                            </TableCell>
                                            <TableCell>
                                                {feeReversal.txRef}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                {feeReversal.reversalTransactionID || '---'}
                                            </TableCell>
                                            <TableCell>
                                                {feeReversal.feeType}
                                            </TableCell>
                                            <TableCell>
                                            <Button
                                                    onClick={() => {
                                                        openFeeReversal(user.Middleware.userID);
                                                        setTxnRef(feeReversal.txRef);
                                                        setAmount(feeReversal.amount);
                                                       
                                                        setOnBoardingLogModal(false);
                                                    }}

                                                    style={{
                                                        backgroundColor:
                                                            feeReversal.isReversed ||
                                                                (feeReversal.status === 'APPROVED' || (feeReversal.status === 'RECHECK' && service.type === 'checker')) ||
                                                                (feeReversal.status === 'CREATE' && service.type === 'maker')
                                                                ? 'gray'
                                                                : 'green',
                                                    }}

                                                    disabled={
                                                        (
                                                            ((feeReversal.status !== 'CREATE' || feeReversal.status === "APPROVED") && service.type === 'checker') ||
                                                            ((feeReversal.status === 'CREATE' || feeReversal.status === "APPROVED") && service.type === 'maker')
                                                        ) ||
                                                        feeReversal.isReversed
                                                    }

                                                >
                                                    {
                                                        feeReversal.isReversed
                                                            ? 'Fee Reversed'
                                                            : (!feeReversal.isReversed &&
                                                                ((feeReversal.status === 'APPROVED' && service.type === 'checker') ||
                                                                    (feeReversal.status === 'APPROVED' && service.type === 'maker')))
                                                                ? 'Fee Reversed'
                                                                : 'Reversal Fee'
                                                    }

                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="FeeRevrsal Log">
                                                    <span
                                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                        onClick={() => feeReversalStatusLog(feeReversal.txRef)}
                                                    >
                                                        <TimelineIcon />
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => {
                            setOnBoardingLogModal(false);
                            setOnBoardingLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* TRAVEL BUDDY LOG MODAL */}

            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={feeReversalLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setFeeReversalLogModal(false);
                    setBfcpModalTitle(false)
                    setFeeReversalLog([]);
                }}
                maxWidth="xl"
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h3 style={{ textAlign: 'center' }}>{bfcpModalTitle ? "Credit/Debit BFC Points Log" : "Fee Reversal Log"}</h3>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {feeLogColumns.map((column) => (
                                        <TableCell
                                            key={column._id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feeReversalLog && feeReversalLog.length > 0 ? (
                                    feeReversalLog.map((row, key) => {
                                        let status = row.status;
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                                <TableCell>
                                                    {moment(row.date).format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(row.date).format("HH:mm:ss")}
                                                </TableCell>
                                                <TableCell>
                                                    {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                                </TableCell>
                                                <TableCell>
                                                    {status}
                                                </TableCell>
                                                <TableCell>
                                                    {row.status_message}
                                                </TableCell>
                                                <TableCell>
                                                    {row.remarks}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                            No Logs found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>


                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => {
                            setFeeReversalLogModal(false);
                            setFeeReversalLog([]);
                        }}
                        color="danger"
                        size="sm"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            {/* DIALOG FOR CHECKER SIDE BFC POINTS */}
            <Dialog
                open={openCheckerModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <h4 style={{ fontFamily: 'sans-serif' }}>Credit/Debit BFC Points</h4>
                    {service.type === "maker" && CDBfcpointsLastData.status !== "RECHECKED" && <Button
                        onClick={() => {
                            handleClose()
                            setOpenMakerModal(true)
                        }}
                        color="primary"
                        size="small"
                        style={{ position: 'absolute', top: 50, right: 50 }}
                    >
                        CREATE NEW
                    </Button>}
                </DialogTitle>

                <DialogContent>
                    <TableContainer >
                        <MuiTable>
                            <TableHead>
                                <TableRow>
                                    {bfcPointsColumns.map((column) => (
                                        <TableCell
                                            key={column._id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getCDBfcpointsData && getCDBfcpointsData.length > 0 ? (
                                    getCDBfcpointsData.map((getCDBfcpoints, key) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(getCDBfcpoints.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {getCDBfcpoints.userID}
                                            </TableCell>
                                            <TableCell>
                                                {getCDBfcpoints.selectedOption}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                {getCDBfcpoints.bfcPoints}
                                            </TableCell>
                                            <TableCell>
                                                {getCDBfcpoints.reason}
                                            </TableCell>
                                            <TableCell>
                                                {getCDBfcpoints.status}
                                            </TableCell>
                                            <TableCell>
                                                {getCDBfcpoints.remarks}
                                            </TableCell>
                                            <TableCell>
                                                {getCDBfcpoints.status !== "APPROVED" && (
                                                    <Button
                                                        style={{ backgroundColor: '#3f51b5', color: '#fff' }}
                                                        disabled={
                                                            (service.type === "checker" && getCDBfcpoints.status === "RECHECKED") ||
                                                            (service.type === "maker" && getCDBfcpoints.status === "CREATED")
                                                        }
                                                        onClick={() => {
                                                            if (service.type === "checker") {
                                                                handleClose()
                                                                setBfcpId(getCDBfcpoints._id)
                                                                setStatusBfcp("RECHECKED")
                                                                setRemarks('')
                                                                setRemarksModal(true)
                                                            } else if (service.type === "maker") {
                                                                handleClose()
                                                                setBfcpId(getCDBfcpoints._id)
                                                                setBfcPoints(getCDBfcpoints.bfcPoints)
                                                                setReason(getCDBfcpoints.reason)
                                                                setOpenMakerModal(true)
                                                            }
                                                        }}
                                                    >
                                                        {service.type === "checker" ? (getCDBfcpoints.status === "RECHECKED" ? "RECHECKED" : "RECHECK") : service.type === "maker" ? (getCDBfcpoints.status === "CREATED" ? "CREATED" : "CREATE") : null}
                                                    </Button>
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                {getCDBfcpoints.status !== "RECHECKED" && service.type === "checker" && (
                                                    <Button
                                                        style={{ backgroundColor: getCDBfcpoints.status === "APPROVED" ? 'gray' : 'navy', color: '#fff' }}
                                                        disabled={getCDBfcpoints.status === "APPROVED"}
                                                        onClick={() => {
                                                            if (service.type === "checker") {
                                                                handleClose()
                                                                setBfcpId(getCDBfcpoints._id)
                                                                setStatusBfcp("APPROVED")
                                                                setRemarks('')
                                                                setRemarksModal(true)
                                                            }
                                                        }}
                                                    >
                                                        {getCDBfcpoints.status === "APPROVED" ? "APPROVED" : "APPROVE"}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                            No data found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={handleClose}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* DIALOG FOR MAKER SIDE BFC POINTS  */}
            <Dialog
                open={openMakerModal || remarksModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ textAlign: 'center' }}>{"Credit/Debit BFC Points"}</DialogTitle>
                {openMakerModal ? <Box display="flex" flexDirection="column" alignItems="center" padding="20px">
                    <TextField
                        label="BFC Points"
                        variant="outlined"
                        margin="normal"
                        style={{ minWidth: '300px' }}
                        value={bfcPoints}
                        onChange={handleBfcPointsChange}
                        error={Boolean(error)}
                        helperText={error}
                    />
                    <TextField label="Reason" variant="outlined" margin="normal" style={{ minWidth: '300px' }} value={reason}
                        onChange={handleReasonChange} />

                    <Box display="flex" marginTop="10px" justifyContent="center">
                        <FormControlLabel
                            value="Credit"
                            control={<Radio color="primary" checked={selectedOption === 'Credit'} onChange={handleRadioChange} />}
                            label="Credit"
                        />
                        <FormControlLabel
                            value="Debit"
                            control={<Radio color="primary" checked={selectedOption === 'Debit'} onChange={handleRadioChange} />}
                            label="Debit"
                        />
                    </Box>
                </Box> :
                    <Box display="flex" flexDirection="column" alignItems="center" padding="20px">
                        <TextField label="Remarks" variant="outlined" margin="normal" style={{ minWidth: '300px' }} value={remarks}
                            onChange={handleRemarksChange} />
                    </Box>
                }
                <DialogActions style={{ justifyContent: 'center' }}>
                    {<Button onClick={handleClose}>Cancel</Button>}
                    <Button color="primary" onClick={() => {
                        if (service.type === "maker") {
                            createCreditOrDebitBfcPointsRequest("CREATED");
                        } else if (service.type === "checker") {
                            addRemarksBfcPoints(remarks)
                        }
                    }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {
                openImageViewer && (
                    <Lightbox
                        mainSrc={documentViewerData}
                        onCloseRequest={() => setOpenImageViewer(false)}
                    />
                )
            }
        </div >
    );
}
